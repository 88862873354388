<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="deUserPage" :fields="fields" :search-model="searchModel"
                :show-selection="true" :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="开发商名称" prop="developersId">
          <el-select size="small" v-model="searchModel.developersId" filterable clearable placeholder="开发商名称">
            <el-option v-for="item in developer_options" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发电户号" prop="powerGenerationNo">
          <el-input size="small" v-model="searchModel.powerGenerationNo" clearable placeholder="发电户号"></el-input>
        </el-form-item>
        <el-form-item label="划转对象" prop="beneficiary">
          <el-select size="small" v-model="searchModel.beneficiary" clearable placeholder="划转对象">
            <el-option label="个人" value="01"></el-option>
            <el-option label="开发商" value="02"></el-option>
            <el-option label="运维费" value="03"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="结算时间" prop="date">
          <el-date-picker size="small" style="width: 300px;"
                          @change="timeChange"
                          value-format="yyyy-MM-dd"
                          v-model="searchModel.date"
                          type="daterange"
                          range-separator="-"
                          start-placeholder="开始时间"
                          end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结算时间" prop="startTime" style="display: none">
          <el-date-picker size="small"
                  value-format="yyyy-MM-dd"
                  v-model="searchModel.startTime"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="结算时间"
                  end-placeholder="结算时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结算时间" prop="endTime" style="display: none">
          <el-date-picker size="small"
                  style="width: 250px"
                  value-format="yyyy-MM-dd"
                  v-model="searchModel.dendTimeate"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="结算时间"
                  end-placeholder="结算时间">
          </el-date-picker>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <div style="margin-bottom: 18px;">
          <el-button @click="sync" size="small" type="primary">一键同步</el-button>
        </div>
      </template>
      <template slot="page-actions-right">
        <div style="margin-bottom: 18px;">
          <el-button style="background-color: #E6EBF0;color: #18191A;border: none;" @click="handleExport" size="small" icon="el-icon-upload2" plain>批量导出</el-button>
        </div>
      </template>

      <template slot="deUserCount">
        <div class="page-card-boody">
          <div style="height:50px;margin-bottom:15px;display: flex;justify-content: space-between; align-items: center;padding: 0 20px">
            <div>
              <span style="color: #909399">划转总金额(元)：</span>
              <span style="color: red">{{otherObject.totalMoney}}</span>
            </div>
            <div>
              <span style="color: #909399">划转总笔数：</span>
              <span style="color: red">{{otherObject.total}}</span>
            </div>
            <div>
              <span style="color: #909399">服务费总金额：</span>
              <span style="color: red">{{otherObject.serverFee}}</span>
            </div>
          </div>
        </div>
      </template>
    </page-table>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {billPage, developerAll, billExport, billSync} from "@/api/common"
import {PROVINCE_CODES} from "@/utils/config";
import downloadBlob from "@/function/downloadBlob";

const stateMap = {
  '00': '未授权',
  '01': '预授权',
  '02': '已授权',
  '03': '授权结束',
  '04': '失败',
  '05': '授权暂停',
  '06': '授权中止',
  '07': '待上传',
  '08': '已上传',
  '09': '上传失败',
  '10': '无效'
}
const beneficiaryMap = {
  '01': "个人",
  '02': "开发商",
  '03': "运维费"
}
const serverTypeMap = {
  '01': "按照交易量",
  '02': "按照还款成功笔数"
}
export default {
  name: "DeUsers",
  data() {
    return {
      searchModel: {
        date: [],
        powerGenerationNo: '',
        state: '',
        developersId: '',
        beneficiary: '',
        startTime: '',
        endTime: "",
      },
      deUserPage: billPage,
      province_options: PROVINCE_CODES,
      otherObject: {},
      fields: [
        {
          label: '省码', width: 100,
          render({areaCode}, h) {
            return h('div', PROVINCE_CODES.find(item => {
              return item.id == parseInt(areaCode)
            }).province)
          }
        },
        {
          label: '发电户号',
          key: 'powerGenerationNo',
          width: 110,
        },
        {label: '结算ID', key: 'gfApplyDetailId', width: 180},
        {label: '账单日期', key: 'billTime', width: 180},
        {label: '结算金额', key: 'totalMoney', width: 100},
        {
          label: '划转对象', width: 120,
          render({beneficiary}, h) {
            return h('div', beneficiaryMap[beneficiary])
          }
        },
        {label: '划转金额(元)', key: 'paymentMoney', width: 100},
        {label: '账户名称', key: 'receivablesName', width: 120,},
        {label: '划转账户', key: 'receivablesCode', width: 120},
        {label: '结算时间', key: 'paymentTime', width: 180},
        {label: '服务费划扣对象', key: 'merchantsName',width: 120},
        {label: '服务费(元)', key: 'serverFee', width: 100},
        {
          label: '计费方式', width:100,
          render({serverType}, h) {
            return h('div', serverTypeMap[serverType])
          }
        },
        {label: '计费标准', key: 'serverFeePip', width: 100},
      ],
      actions: [],
      stateMap: stateMap,
      developer_options: [],
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  // watch:{
  //   'searchModel.date': {
  //     handler(newValue, oldValue) {
  //       console.log(newValue, oldValue)
  //     },
  //     deep: true
  //   }
  // },
  mounted() {
    this.getDeveloperOptions()
  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    getDeveloperOptions() {
      let that = this
      developerAll().then(res => {
        that.developer_options = res
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    infoAction(item) {
      this.$router.push({path: `/admin/de-user/info/${item.id}`})
    },
    timeChange(val) {
      console.log('timechange', val)
      if (val != null) {
        this.searchModel.startTime = val[0]
        this.searchModel.endTime = val[1]
      } else {
        this.searchModel.startTime = ""
        this.searchModel.endTime = ""
      }
    },
    handleExport() {
      let that = this, ids = []
      let selected = that.$refs.table.selection
      for (let item of selected) {
        ids.push(item.id)
      }
      let data = Object.assign(that.searchModel, {
        exportIds: ids.length ? ids.join(',') : ''
      })
      billExport(data).then(res => {
        downloadBlob(res)
      }).catch(err => {
        console.log('划转对账查询 error', err)
      })
    },
    sync() {
      let that = this;
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      billSync(that.searchModel.developersId).then(() => {
        loading.close();
        that.$message.success("同步完毕");
        that.$refs.table.loadData()
      }).catch(err => {
        loading.close();
        console.log(err)
        that.$message.error("同步错误");
      });
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
